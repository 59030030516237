<template>
  <li>
    <label ><span>*</span>基本设置：</label>
    <ul class="operation-menus setting-invite">
      <ul>
        <li>
          <label ><span>*</span>选择图片：</label>
          <div class="line-con line-select" :class="`operation-menu ${global.checkBlank(file.url)?'act':''}`">
            <input type="file" ref="inviteFileUrl" accept="image/*" @change="refTriggerFile('inviteFileUrl')">
            <p class="menu-item" @click="clickRef('inviteFileUrl')"
            ><span>{{ file.name || '选择图片' }}</span><i class="el-icon-arrow-right el-icon--right"></i></p>
          </div>
        </li>
        <li>
          <label ><span>*</span>图片宽度：</label>
          <div class="line-con line-menu" :class="`operation-menu ${global.checkBlank(file.width)?'act':''}`" style="padding-left:9px;">
            <input type="text" placeholder="输入数字,默认1080" v-model="file.width">
            <span class="unit">px(像素)</span>
          </div>
        </li>
        <li style="margin-bottom:12px;">
          <label style="line-height:20px; opacity:0.8;">如需批量生成,每组文字可用，分割文字<br>如：姓名1，姓名2，姓名3，...</label>
        </li>
      </ul>
      <slot v-if="name.length === group">
        <ul v-for="gIndex in group" :key="'t_g_'+gIndex">
          <li style="margin-top:6px;">
            <label >第{{ gIndex }}组文字<slot v-if="gIndex>0&&groupTip[gIndex-1]"> (如 {{ groupTip[gIndex-1] }} 等)</slot>：</label>
          </li>
          <li>
            <label ><span>*</span>输入文字：</label>
            <div class="line-con" :class="`operation-menu ${(name[gIndex-1] && name[gIndex-1].value&&name[gIndex-1].value.length)?'act':''}`">
              <input type="text" placeholder="输入文字" v-model="name[gIndex-1].value" @keyup.enter="toSubmit">
            </div>
          </li>
          <li>
            <label ><span>*</span>字体：</label>
            <div class="line-con line-select" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].fontFamily)?'act':''}`">
              <el-dropdown trigger="click">
                <p class="menu-item"
                >{{ nameStyle[gIndex-1] && nameStyle[gIndex-1].fontFamily && nameStyle[gIndex-1].fontFamily.replace('_', '') }} <i class="el-icon-arrow-down el-icon--right"></i></p>
                <el-dropdown-menu slot="dropdown" class="operation-menu-year">
                  <el-dropdown-item v-for="(item,index) in options.fontFamily" :key="'+'+index">
                    <p @click="setStyle('fontFamily', gIndex, item)">{{ item && item.replace('_', '') }}</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
          <li>
            <label ><span>*</span>字重：</label>
            <div class="line-con line-select" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].fontWeight)?'act':''}`">
              <el-dropdown trigger="click">
                <p class="menu-item">
                  {{ nameStyle[gIndex-1] && nameStyle[gIndex-1].fontWeight }} <i class="el-icon-arrow-down el-icon--right"></i>
                </p>
                <el-dropdown-menu slot="dropdown" class="operation-menu-year">
                  <el-dropdown-item v-for="(item,index) in options.fontWeight" :key="'+'+index">
                    <p @click="setStyle('fontWeight', gIndex, item)">{{ item }}</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
          <li>
            <label ><span>*</span>字号：</label>
            <div class="line-con line-menu" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].fontSize)?'act':''}`" style="padding-left:9px;">
              <input type="text" placeholder="输入数字,默认16" v-model="nameStyle[gIndex-1].fontSize">
              <span class="unit">px(像素)</span>
            </div>
          </li>
          <li>
            <label ><span>*</span>文字样式：</label>
            <div class="line-con line-select" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].fontStyle)?'act':''}`">
              <el-dropdown trigger="click">
                <p class="menu-item">
                  {{ nameStyle[gIndex-1] && nameStyle[gIndex-1].fontStyle }} <i class="el-icon-arrow-down el-icon--right"></i>
                </p>
                <el-dropdown-menu slot="dropdown" class="operation-menu-year">
                  <el-dropdown-item v-for="(item,index) in options.fontStyle" :key="'+'+index">
                    <p @click="setStyle('fontStyle', gIndex, item)">{{ item }}</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
          <li>
            <label ><span>*</span>文字变形：</label>
            <div class="line-con" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].transform)?'act':''}`">
              <input type="text" placeholder="如: rotate(15deg) 即旋转15度" v-model="nameStyle[gIndex-1].transform">
            </div>
          </li>
          <li>
            <label ><span>*</span>文字颜色：</label>
            <div class="line-con" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].color)?'act':''}`">
              <input type="text" placeholder="如: white 或 #ffffff 等" v-model="nameStyle[gIndex-1].color">
            </div>
          </li>
          <li>
            <label ><span>*</span>文字位置：</label>
            <div class="line-con line-menu" :class="`operation-menu ${nameStyle[gIndex-1] && global.checkBlank(nameStyle[gIndex-1].top)?'act':''}`" style="margin-bottom:0;">
              <span>top:</span>
              <input type="text" placeholder="输入数字" v-model="nameStyle[gIndex-1].top">
              <span class="unit">px(像素)</span>
            </div>
          </li>
          <li>
            <label style="opacity:0.4;font-size:12px;line-height:16px;"><span style="opacity:0;">*</span>(默认 [0,0])</label>
            <div class="line-con line-menu" :class="`operation-menu ${global.checkBlank(nameStyle[gIndex-1].left)?'act':''}`">
              <span>left:</span>
              <input type="text" placeholder="输入数字或center" v-model="nameStyle[gIndex-1].left">
              <span class="unit">px(像素)</span>
            </div>
          </li>
        </ul>
      </slot>
      <ul>
        <li>
          <label style="opacity:0;"><span>*</span>确认生成：</label>
          <button class="operation-menu act act-btn" @click="toSubmit">确认</button>
        </li>
      </ul>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'InviteSetting',
  data () {
    return {
      file: { url: '' },
      group: 3,
      groupTip: ['姓名', '公司/专题', '职位/职务/职责'],
      name: [],
      nameStyle: [],
      baseName: { default: '', value: '' },
      baseNameStyle: { top: '', left: '', color: '#000000', fontSize: '', fontWeight: '400', fontStyle: 'normal', transform: '', fontFamily: 'Alibaba_PuHuiTi_Medium' },
      options: {
        fontFamily: ['Alibaba_PuHuiTi_Medium', 'Alibaba_PuHuiTi_Bold', 'Alibaba_PuHuiTi_Regular', 'Alibaba_PuHuiTi_Heavy'],
        fontWeight: ['100', '200', '300', '400', '500', '600', '700', '800', '900', 'bold', 'bolder', 'lighter', 'normal'],
        fontStyle: ['normal', 'italic', 'oblique'],
      }
    }
  },
  created () {
    this.initSetting()
  },
  methods: {
    initSetting () {
      if (this.global.year && this.global.year.toString().includes('2025')) {
        this.file = { width: 1600, name: '2025历届讲师首发案例.jpg', url: 'https://f.msup.com.cn/card/giac2025%E9%A6%96%E5%8F%91%E6%A1%88%E4%BE%8B_%E5%BE%80%E5%B1%8A%E8%AE%B2%E5%B8%88%E9%82%80%E8%AF%B7_1.png' }
        this.group = 3
      }
      for (let i=0; i<this.group; i++) {
        this.name.push(this.global.deepClone(this.baseName))
        this.nameStyle.push(this.global.deepClone(this.baseNameStyle))
        if (i === 0) {
          this.name[i].default = this.name[i].default || '测试文本'
          this.name[i].value = this.name[i].value || '测试'
        }
      }
      if (this.global.year && this.global.year.toString().includes('2025')) {
        if (this.$route.query && this.$route.query.test) {
          this.name[0].value = '陈尔冬，姓名'
          this.name[1].value = '智能运维，专题'
          this.name[2].value = '出品人'
        } else {
          this.name[0].value = this.groupTip[0]
          this.name[1].value = this.groupTip[1]
          this.name[2].value = this.groupTip[2]
        }
        this.nameStyle[0].fontSize = '104'
        // this.nameStyle[0].fontStyle = 'oblique' // skewX(-15deg)
        this.nameStyle[0].transform = 'skewX(-9deg)'
        this.nameStyle[0].fontWeight = '500'
        this.nameStyle[0].fontFamily = 'Alibaba_PuHuiTi_Heavy'
        this.nameStyle[0].top = '700'
        this.nameStyle[0].left = '260'
        this.nameStyle[1].fontSize = '48'
        this.nameStyle[1].fontWeight = '500'
        // this.nameStyle[1].fontStyle = 'oblique'
        this.nameStyle[1].transform = 'skewX(-9deg)'
        this.nameStyle[1].top = '745'
        this.nameStyle[2].fontSize = '48'
        this.nameStyle[2].fontWeight = '500'
        // this.nameStyle[2].fontStyle = 'oblique'
        this.nameStyle[2].transform = 'skewX(-9deg)'
        this.nameStyle[2].top = '745'
      }
      setTimeout(() => {
        this.toSubmit();
      }, 1000)
    },
    clickRef (name) {
      if (name) {
        this.global.clickRef(name, this)
      }
    },
    refTriggerFile (name) {
      if (name && this.$refs && this.$refs[name] && this.$refs[name].files && this.$refs[name].files[0]) {
        this.file = { name: this.$refs[name].files[0].name }
        this.global.triggerFile(this.$refs[name].files[0]).then(res => {
          this.file.url = res
          this.$forceUpdate()
        })
      }
    },
    setStyle (key, index, value) {
      if (this.nameStyle[index]) {
        let keys = Object.keys(this.baseNameStyle)
        if (value && keys.includes(key)) {
          this.nameStyle[index][key] = value
        }
      }
    },
    getUploadUrl (file) {
      console.log(file)
    },
    toSubmit () {
      let maxLen = 0
      for (let item of this.name) {
        if (item.value && item.value.split('，').length > maxLen) {
          maxLen = item.value.split('，').length
        }
      }
      // console.log(maxLen, this.group)
      let arr = []
      for (let i=0; i<this.group; i++) {
        let styles = []
        for (let j=0; j<maxLen; j++) {
          styles.push({...this.nameStyle[i], i:j})
        }
        arr.push({...this.name[i], styles})
      }
      // // 》》》》》》
      if (this.global.year && this.global.year.toString().includes('2025')) {
        // console.log(arr[0], arr[1], arr[2])
        let nameArr = (this.name[0].value && this.name[0].value.split('，')) || []
        let companyArr = (this.name[1].value && this.name[1].value.split('，')) || []
        let positionArr = (this.name[2].value && this.name[2].value.split('，')) || []
        let len0 = arr[0].styles[0].left
        len0 = (len0 && len0!=='center') ? parseInt(len0) : 0
        for (let l=0; l<maxLen; l++) {
          // console.log(nameArr[l], nameArr[l].length, companyArr[l], positionArr[l])
          let len1 = len0 + ((nameArr[l] && nameArr[l].length) || 0) * (parseInt(arr[0].styles[l].fontSize || 0) || 0) + 30
          let len2 = len1 + ((companyArr[l] && companyArr[l].length) || 0) * (parseInt(arr[1].styles[l].fontSize || 0) || 0) + 12
          // console.log(l, len1, len2, arr[1], arr[2])
          arr[1].styles[l].left = arr[1].styles[l].left || len1
          arr[2].styles[l].left = arr[2].styles[l].left || len2
        }
      }
      this.$emit('setinvite', {file: this.file, text: arr, group: this.group})
    }
  }
}
</script>

<style lang="scss">
.page-poster-index .operation-area .operation-con ul li ul.setting-invite {
  padding-top: 0;
  padding-right: 0;
  li {
    label {
      min-width: 72px;
      line-height: 31px;
      padding-top: 0;
    }
    .line-con {
      min-height: 31px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.line-menu {
        padding-left: 32px;
        padding-right: 55px;
        span {
          display: block;
          width: 28px;
          top: 50%;
          font-size: 13px;
          transform: translateY(-50%);
          padding: 0 2px;
          // border-right: 1px solid #dfdfdf;
          &.unit {
            width: 52px;
            // border-left: 1px solid #dfdfdf;
            // border-right: none;
          }
        }
      }
      &.line-select {
        padding: 0;
      }
      input {
        &::placeholder {
          font-size: 13px;
        }
      }
      .el-dropdown {
        width: 100%;
      }
      p.menu-item {
        width: 100%;
        max-width: 200px;
        padding: 5px 25px 5px 9px;
        margin: 0;
        color: #a0a0a0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        span {
          width: calc(100% - 25px);
          padding-left: 9px;
          word-break: break-all;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        i {
          position: absolute;
          right: 9px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    &:nth-last-of-type(1) {
      padding-bottom: 0;
    }
  }
  ul {
    padding-top: 0;
    padding-right: 0;
    &:nth-last-of-type(2) li:nth-last-of-type(1) {
      padding-bottom: 12px;
    }
    &:nth-last-of-type(1) {
      padding-bottom: 35px;
    }
  }
}
</style>